<template>
    <div class="wrap p-main">
        <layout-header title="부모페이지 - 회원정보"></layout-header>
        <header class="header-main">
            <h1 class="blind">Masha and the Bear</h1>
            <h2 class="blind">메인 홈</h2>
            <button class="btn btn-menu modal-open" data-pop="snb" type="button"><span class="blind">메뉴</span></button>
            <div class="user-info">
                <div class="user-name">
                    <a href="javascript:;">
                        <img :src="coAccountVO.profileSrc" alt="">
                        <strong>{{ coAccountVO.nickName }}</strong>
                    </a>
                </div>
                <div class="user-attend" v-if="maxAttendance > 0">
                    <b>{{ maxAttendance }}</b>
                    <span>일 연속 출석</span>
                </div>
            </div>
            <div class="util-link">
                <router-link :to="{name: 'attendance'}" class="btn-attend" href="javascript:;"><span class="blind">출석현황</span></router-link>
                <router-link :to="{ name: 'myEducation' }" class="btn-study" href="javascript:;"><span class="blind">학습현황</span></router-link>
                <router-link :to="{ name: 'mySticker' }" class="btn-praise" href="javascript:;"><span class="blind">칭찬 스티커</span></router-link>
            </div>
        </header>

        <!-- snb -->
        <div id="snb" class="snb modal">
            <div class="inner">
                <button class="btn-close modal-close" type="button"><span class="blind">닫기</span></button>
                <div class="user-info">
                    <img :src="coAccountVO.profileSrc" alt="">
                    <a style="background-image: none; padding-left: 40px">{{ coAccountVO.nickName }}</a>
                    <router-link :to="{name: 'profile'}" style="width: 40px">&nbsp;</router-link>
                </div>
                <ul class="menu-list">
                    <li>
                        <a @click="pageOpen('profileMyPage')" href="javascript:;">마이페이지</a>
                    </li>
                    <li>
                        <a @click="pageOpen('accountMyPage')" href="javascript:;">부모페이지</a>
                        <ul>
                            <li>
                                <a @click="pageOpen('accountMyPage')" href="javascript:;">회원정보</a>
                            </li>
                            <li>
                                <router-link :to="{name: 'profileJoin'}">자녀추가</router-link>
                            </li>
                        </ul>
                    </li>
                </ul>
                <div class="banner"></div>
            </div>
        </div>


        <!-- container -->
        <div class="container">
            <div class="content">
                <div class="main-learn">
                    <div v-if="recentAnswer != null" class="book-box" @click="goContentList(recentAnswer.eduMainVO.emIdx, recentAnswer.levelNum )">
                        <div class="book-vol">
                            <a href="javascript:;">{{ recentAnswer.eduMainVO.shortTitle }} <i></i></a>
                        </div>
                        <div class="book-tit">
                            <a href="javascript:;">{{ recentAnswer.eduMainVO.eduTitle }}</a>
                        </div>
                        <div class="learn-progress">
                            <strong>나의 학습 진행률</strong>
                            <!-- 진행에 따라 active 추가 -->
                            <i v-for="num in guageMax" :class="{ 'active': (num <= guageNum) }"></i>
                            <span class="per">{{ guagePercen }}</span>
                        </div>
                    </div>
                    <div class="phonics-btn">
                        <router-link :to="{ name: 'phonics' }">Fun<br>Phonics</router-link>
                    </div>
                    <div class="guide-btn">
                        <router-link :to="{ name: 'guide' }">Guide</router-link>
                    </div>
                </div>


                <ul class="main-level">
                    <!-- 열람가능한 레벨 컨텐츠에 active -->
                    <li v-for="(item, index) in courseList" :class="[{ 'active' : (item.isActive), 'lock' : (!item.isOpen) }, 'level' + (index + 1) + '-item']">
                        <template v-if="!item.isOpen">
                            <div style="float: right;margin: 15px;background: white;width: 28px;height: 28px;border-radius: 14px;background: url(/assets/images/main/ico_main_lock.png) no-repeat center/16px;background-color: white;">
                            </div>
                        </template>
                        <router-link :to="{ path: '/mainList/', query: { level: (index+1), cidx: item.cidx } }">
                            <strong>{{ item.shortTitle }}</strong>
                        </router-link>
                    </li>

                    <!--					<li class="level1-item active">
                                            <a href="javascript:;">
                                                <strong>Level 1</strong>
                                            </a>
                                        </li>
                                        <li class="level2-item">
                                            <a href="javascript:;">
                                                <strong>Level 2</strong>
                                            </a>
                                        </li>
                                        <li class="level3-item">
                                            <a href="javascript:;">
                                                <strong>Level 3</strong>
                                            </a>
                                        </li>-->
                </ul>
            </div>
        </div>
    </div>

</template>

<script>
import LayoutHeader from "@/pages/layouts/layoutHeader";
import $ from 'jquery'

export default {
    name: "main",
    components: {LayoutHeader},
    data() {
        return {
            maxAttendance: 0,
            coAccountVO: Object,

            params: this.pageUtils.Params("shortTitle,ASC"),
            // eslint-disable-next-line no-mixed-spaces-and-tabs
            courseList: [],

            // 최신 교육내용 조회
            guageNum: 0,
            guageMax: 7,
            guagePercen: 0,
            recentAnswer: null,
        }
    },
    created() {

        this.$eventBus.$emit("startProgress")

        this.requireLoginWithoutAlert(this)
        this.getLastStep();
        this.getOpenCourse();
    },
    beforeMount() {
        this.doRecordIfNotRecordedToday()
    },
    mounted() {

        $(".modal-open").on("click", function () {
            var attr = $(this).attr("data-pop")
            $("#" + attr).fadeIn()
        })
        $(".modal-close").on("click", function () {
            $(".modal").fadeOut()
        })

        this.coAccountVO = this.$session.get("HWSSession")

        this.getMaxAttendance()

    },
    methods: {


        /**
         * 사용자의 열린 교과정 조회
         */
        getOpenCourse() {

            this.$eventBus.$emit("startProgress")

            const self = this

            this.params.openPrevCourse = false
            this.params.openPrevMain = true
            this.params.cateCode = "200101"
            this.axiosCaller.get(self, this.APIs.EDU_COURSE + "/getOpenCourseList", this.params, (res) => {

                const result = res.data
                if (result.status === "ok") {

                    this.courseList = result.list
                    for (let i = this.courseList.length - 1; i >= 0; i--) {
                        if (this.courseList[i].isOpen) {
                            this.courseList[i].isActive = true;
                            break;
                        }
                    }

                } else {
                    this.swalUtils.gritter(result.status, result.msg, "error")
                }

                this.$eventBus.$emit("doneProgress")
            })

        },


        /**
         * 사용자의 최근 답변 데이터 조회
         */
        getLastStep() {

            const self = this
            this.axiosCaller.get(this, this.APIs.EDU_MINE + "/getLastStep", {
                userId: this.getLoginId(),
	            cateCode: "200101",
            }, res => {
                const result = res.data
                if (result.status === "ok") {

					const levelStr = self.stringUtils.rightString( result.eduCourseVO.shortTitle, 1 );
	                let levelNum = 1;
					if ( levelStr.toLowerCase() === "a" ) {
						levelNum = 1
					} else if ( levelStr.toLowerCase() === "b" ) {
						levelNum = 2
					} else if ( levelStr.toLowerCase() === "c" ) {
						levelNum = 3
					}

                    self.recentAnswer = {
                        answerList: result.answerList,
                        eduCourseVO: result.eduCourseVO,
                        eduMainVO: result.eduMainVO,
                        contentList: result.eduMainVO.contentList,
	                    levelNum: levelNum,
                    }

                    if (result.answerList !== undefined && result.answerList.length > 0) {

                        for (let i = 0; i < self.recentAnswer.answerList.length; i++) {
                            const answer = self.recentAnswer.answerList[i];
                            if (answer.isComplete) {
                                if (self.recentAnswer.contentList[i].qstMovVO != null) {

                                    if (self.recentAnswer.contentList[i].qstMovVO.movTitle.toLowerCase().indexOf("week") > -1) {
                                        self.guagePercen += 15;
                                    } else {
                                        self.guagePercen += 10;
                                    }

                                } else {
                                    if ( answer.ansTstList !== undefined && answer.ansTstList.length > 0) {
                                        self.guagePercen += 20;
                                    }
                                }
                                self.guageNum++;
                            }
                        }
                    }
                }
            })
        },


        goContentList(_emIdx, _level) {

            this.$router.push({
                path: '/contentList/',
                query: {
                    emIdx: _emIdx,
                    level: _level,
                }
            })

        },


        getMaxAttendance() {
            const self = this

            this.axiosCaller.get(self, self.APIs.CO_ATTENDANCE + '/getCurrentMaxAttendance', {
                coEmailId: self.coAccountVO.coEmailId
            }, (res) => {

                if (res.data.status === 'ok') {
                    self.maxAttendance = res.data.currentMaxAttendance
                }
            })
        },

        doRecordIfNotRecordedToday() {

            const self = this

            const convertDate = (_yyyyMMdd) => {
                return _yyyyMMdd.length === 7 ? [_yyyyMMdd.slice(0, 4), 0, _yyyyMMdd.slice(4)].join('') : _yyyyMMdd
            }
            let nowDt = convertDate(new Date().getFullYear() + '' + (new Date().getMonth() + 1) + '' + new Date().getDate())

            this.axiosCaller.post(self, self.APIs.CO_ATTENDANCE + '/doAttendance', {
                coEmailId: self.coAccountVO.coEmailId,
                nowDt: nowDt
            })

        },

        pageOpen(link, target, isCloseBTN) {

            const url = this.APIs.BASE_URL + "/" + link

            const temp = {
                url: url,
                isCloseBTN: "Y",
            }
            this.appOpen(temp);
        }
    }
}
</script>

<style scoped>

</style>